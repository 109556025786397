/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"} description={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."} seoTitle={"Ceník ubytování - Chalupa Ořešák u Kouřimi"} ogTitle={"Ceník ubytování - Chalupa Ořešák u Kouřimi"} ogDescription={"Chalupa nabízí ubytování (nejen) pro rodiny s dětmi, na víkendové nebo letní pobyty. Dále podkrovní sál na jógu či jiné sebe~realizační akce."}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--80" style={{"backgroundColor":"var(--color-custom-1)","marginBottom":0}} name={"uvod-0"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}} animS={"3"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Ceník ubytování</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"marginBottom":0,"paddingTop":0}} name={"cenik"} layout={"l13"} lightbox={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 flex--center" style={{"maxWidth":"","marginBottom":33,"paddingBottom":1}} anim={"2"} animS={"3"} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":1080}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/3e60342dee9249ae810ca50c4a83c998_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38340/3e60342dee9249ae810ca50c4a83c998_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/3e60342dee9249ae810ca50c4a83c998_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/3e60342dee9249ae810ca50c4a83c998_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/3e60342dee9249ae810ca50c4a83c998_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38340/3e60342dee9249ae810ca50c4a83c998_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"Víkend"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":360}} content={"Cena zahrnuje ubytování na 2 noci, <br>ložní prádlo, ručníky, základní hygienické potřeby, utěrky, mycí prostředky do kuchyně, spotřebované dřevo do kachlových kamen, <br> wifi a závěrečný úklid. <br>Cena je již včetně nákladů <br>na spotřebovanou energii.<br>~<br>Cena za psa je 80,- Kč/den.<br>"}>
              </Text>

              <Text className="text-box" style={{"maxWidth":360}} content={"10 000,— Kč&nbsp;/ víkend<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":1080}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/d6644472c6ee4974932ed91ae48a8930_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38340/d6644472c6ee4974932ed91ae48a8930_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/d6644472c6ee4974932ed91ae48a8930_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/d6644472c6ee4974932ed91ae48a8930_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/d6644472c6ee4974932ed91ae48a8930_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38340/d6644472c6ee4974932ed91ae48a8930_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"Týden"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":360}} content={"Cena zahrnuje ubytování na 7 nocí,\n<br>ložní prádlo, ručníky, základní hygienické potřeby, utěrky, mycí prostředky do kuchyně, spotřebované dřevo do kachlových kamen, <br>wifi a závěrečný úklid.\n<br>Cena je již včetně nákladů\n<br>na spotřebovanou energii.<br>~<br>Cena za psa je 80,- Kč/den."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":360}} content={"28 000,— Kč&nbsp;/ týden<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image style={{"maxWidth":1080,"marginTop":38,"marginBottom":5,"paddingBottom":6}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38340/2abca85575e141f0b64fb741a4b9faba_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/38340/2abca85575e141f0b64fb741a4b9faba_s=350x_.jpg 350w, https://cdn.swbpg.com/t/38340/2abca85575e141f0b64fb741a4b9faba_s=660x_.jpg 660w, https://cdn.swbpg.com/t/38340/2abca85575e141f0b64fb741a4b9faba_s=860x_.jpg 860w, https://cdn.swbpg.com/t/38340/2abca85575e141f0b64fb741a4b9faba_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/38340/2abca85575e141f0b64fb741a4b9faba_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--center" style={{"maxWidth":360}} content={"Podkrovní sál<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":360}} content={"Podkrovní sál s kapacitou 11 osob (na podložkách, na sezení i více), pro různá různá (sebe)realizační setkávání, teambuildingy, atd.<br> ~<br>Mohu nabídnout spolupráci na případném programu (jóga, zpěv, tanec, vaření, ženské/mužské kruhy, terapeutické práce, atd. ). \n<br>~<br>Sál lze využít i samostatně, např. na celodenní workshop, bez noclehu v objektu."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":360}} content={"4 000,— Kč&nbsp;/ víkend<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"bkg5nzyoav"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":1306,"marginTop":17}} content={"<i><i><i>V případě rezervace požadujeme uhradit zálohu 50% z celkové ceny, a to do 7 dní od vyzvání, jinak rezervaci nedržíme. \n<br>Storno podmínky: při stornu 30 dní a více před datem nástupu vracíme zpátky celou částku. Při stornu 29 dní a méně před datem nástupu záloha propadá pronajímateli.<br>Příjezd standardně v 16 hod. a předání chaloupky do 10 hod. U víkendových pobytů lze prodloužit na základě předchozí domluvy.<br>Na pobyt vybíráme kauci ve výši 5 000,- Kč.<br>V období 29. 6. až 31. 8. 2025 je objekt pronajímán minimálně na 3 noci.</i><br></i><br></i>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1bzvarv --parallax pb--60" style={{"paddingTop":112}} name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/38340/756da4205f0344a88dd392cc39d0e5ca_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">+420 608 859 625<br>chalupa.oresak@gmail.com</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":""}} anim={""} animS={"2"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":469,"paddingRight":0,"paddingLeft":0}} content={"<span style=\"color: var(--color-dominant);\">~ Stylové zázemí, které podtrhuje <br>ducha starého stavení ~</span><span style=\"color: var(--color-dominant);\"><br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Nová Ves III 44 <br>Svojšice okr. Kolín 280 02<br>+420 608 859 625<br>chalupa.oresak@gmail.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}